import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { stringify } from "qs";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import { CruiseDestinationBlock } from "main/javascripts/components/cruise/CruiseDestinationBlock";
import { ButtonWithLoader } from "main/javascripts/components/button/ButtonWithLoader";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { maxWidth } from "main/javascripts/styles/base/maxWidthStyle";
import {
  FormNamespaces,
  yupDefaultMessages,
} from "main/javascripts/constants/FormConstants";
import { FormErrorsBlock } from "main/javascripts/components/form/FormErrorsBlock";
import { SearchCruiseFilterBlock } from "./SearchCruiseFilterBlock";
import {
  clearCruises,
  convertSearchCruiseFormValuesToParams,
} from "../../cruise/cruiseSlice";
import { useAppDispatch } from "../../../store";
import { useAppSelector } from "main/javascripts/store";
import { cruiseDefaultDestinationSuggestionsSelector } from "main/javascripts/slices/accountParamSlice";

type SearchCruiseFormData = {
  form?: any; // エラー用
  origin_destination_region_ids: number[];
  min_departure_date: string;
  max_departure_date: string;
  min_stays: number;
  max_stays: number;
  origin_region_ids: number[];
  vessel_brand_id: number[];
};

yup.setLocale(yupDefaultMessages);
const schema = yup.object().shape({});

const defaultValues = {};

export const SearchCruise = (): React.ReactElement => {
  const router = useRouter();
  const { t } = useTranslation(["button"]);
  const appDispatch = useAppDispatch();

  const defaultCruiseDestinationSuggestions =
    useAppSelector(cruiseDefaultDestinationSuggestionsSelector) || [];
  const defaultDestinations = defaultCruiseDestinationSuggestions.map(
    (suggestion) => suggestion.region
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<SearchCruiseFormData>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    // setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    clearErrors("form");
    handleSubmit(onSubmit)(e);
  };

  const onSubmit: (values: any) => void = async (values: any) => {
    setIsSubmitting(true);
    appDispatch(clearCruises());
    const params = convertSearchCruiseFormValuesToParams(values);
    router.push(
      `/cruises?${stringify(params, {
        arrayFormat: "indices",
      })}`
    );
  };

  return (
    <div css={blockStyle}>
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          <div css={firstBlockStyle}>
            <div css={destBlockStyle}>
              <CruiseDestinationBlock
                defaultDestinations={defaultDestinations}
                labelColorStyleKey="default"
                borderColorStyleKey="secondary"
              />
            </div>
          </div>
          <SearchCruiseFilterBlock />
          {errors && (
            <div>
              <FormErrorsBlock
                namespace={FormNamespaces.cruise}
                errors={errors}
                hideValidationErrors={true}
              />
            </div>
          )}
          <div css={submitBlockStyle}>
            <ButtonWithLoader
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {t("common.search")}
            </ButtonWithLoader>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const submitBlockStyle = css`
  padding: ${space.atom2x} 0 ${space.atom2x};
  ${moreThanBreakpoint("desktop")} {
    padding-top: ${space.atom2x};
    width: 50%;
    margin-left: auto;
    margin-right: 0;
  }
`;
const blockStyle = css`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 ${space.atom};
  ${moreThanBreakpoint("tablet")} {
    padding: 0 ${space.atom};
    margin: ${space.atom} auto ${space.atom2x};
  }
  ${moreThanBreakpoint("desktop")} {
    box-sizing: border-box;
    max-width: ${maxWidth.page1colWide};
  }
`;
const firstBlockStyle = css`
  padding: ${space.atom} 0 0;
`;
const destBlockStyle = css`
  flex: 1;
  ${moreThanBreakpoint("desktop")} {
    width: 50%;
  }
`;

import React from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { searchCategories } from "main/javascripts/constants/SearchCategories";
import { Icon } from "main/javascripts/components/atoms/Icon";
import { useAppDispatch, useAppSelector } from "main/javascripts/store";
import {
  changeSearchCategory,
  searchCategorySelector,
} from "../searchPageSlice";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { maxWidth } from "main/javascripts/styles/base/maxWidthStyle";
import {
  accentColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { SvgIcon } from "../../../components/icon/SvgIcon";

export const CategoryTab: React.FC = (): React.ReactElement => {
  const { t } = useTranslation(["component"]);

  const appDispatch = useAppDispatch();

  const searchCategory = useAppSelector(searchCategorySelector);
  const hideCategories = [];

  const onClickHotel: () => void = () => {
    appDispatch(changeSearchCategory(searchCategories.hotel));
  };
  const onClickFlight: () => void = () => {
    appDispatch(changeSearchCategory(searchCategories.flight));
  };
  const onClickDynamicPackage: () => void = () => {
    appDispatch(changeSearchCategory(searchCategories.dynamicPackage));
  };
  const onClickCruise: () => void = () => {
    appDispatch(changeSearchCategory(searchCategories.cruise));
  };
  const isDisplayCategory = (category: string): boolean => {
    return !(hideCategories && hideCategories.includes(category));
  };

  const { hotel, flight, dynamicPackage, cruise } = searchCategories;
  return (
    <div css={blockStyle}>
      <div css={innerStyle}>
        {isDisplayCategory(hotel) && (
          <div css={tabContainerStyle}>
            <div
              onClick={onClickHotel}
              css={[
                tabStyle,
                searchCategory === hotel ? tabSelectedStyle : null,
              ]}
            >
              <div css={iconStyle}>
                <Icon styleKey="hotel" />
              </div>
              <span css={labelStyle}>{t("search.hotel")}</span>
            </div>
          </div>
        )}
        {isDisplayCategory(flight) && (
          <div css={tabContainerStyle}>
            <div
              onClick={onClickFlight}
              css={[
                tabStyle,
                searchCategory === flight ? tabSelectedStyle : null,
              ]}
            >
              <div css={iconStyle}>
                <Icon styleKey="airplane" />
              </div>
              <span css={labelStyle}>{t("search.flight")}</span>
            </div>
          </div>
        )}
        {isDisplayCategory(dynamicPackage) && (
          <div css={[tabContainerStyle, tabContainerWideStyle]}>
            <div
              onClick={onClickDynamicPackage}
              css={[
                tabStyle,
                searchCategory === dynamicPackage ? tabSelectedStyle : null,
              ]}
            >
              <div css={iconStyle}>
                <Icon styleKey="airplane" />
              </div>
              <span css={verticalMiddleStyle}>+</span>
              <div css={[iconStyle, iconMultiStyle]}>
                <Icon styleKey="hotel" />
              </div>
              <span css={labelStyle}>
                {t("search.flight")} + {t("search.hotel")}
              </span>
            </div>
          </div>
        )}
        {isDisplayCategory(cruise) && (
          <div css={tabContainerStyle}>
            <div
              onClick={onClickCruise}
              css={[
                tabStyle,
                searchCategory === cruise ? tabSelectedStyle : null,
              ]}
            >
              <div css={svgIconStyle}>
                <SvgIcon name="cruise" color={textColor.primaryDarkColor} />
              </div>
              <span css={labelStyle}>{t("search.cruise")}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const blockStyle = css`
  padding: ${space.atom} 0 0;
  max-width: 600px;
  ${moreThanBreakpoint("tablet")} {
    padding: ${space.atom3x} ${space.atom} 0;
    margin: 0 auto;
  }
  ${moreThanBreakpoint("desktop")} {
    box-sizing: border-box;
    max-width: ${maxWidth.page1colWide};
  }
`;
const innerStyle = css`
  display: flex;
  border-bottom: 1px solid ${textColor.primaryDarkColor};
  ${moreThanBreakpoint("tablet")} {
    margin: 0 ${space.atom};
  }
`;
const tabContainerStyle = css`
  box-sizing: border-box;
  width: 23%;
  ${moreThanBreakpoint("tablet")} {
    width: auto;
  }
`;
const tabContainerWideStyle = css`
  width: 31%;
  ${moreThanBreakpoint("tablet")} {
    width: auto;
  }
`;
const tabStyle = css`
  position: relative;
  cursor: pointer;
  padding: 0.4rem 0.1rem 0;
  font-size: ${fontSize.smallest};
  text-align: center;
  height: 48px;
  color: ${textColor.primaryDarkColor};
  box-sizing: border-box;
  transition: color 0.3s ease;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: transparent;
    z-index: 1;
    transition: background-color 0.3s ease;
  }
  &:hover {
    color: ${textColor.secondaryDarkColor};
    &:after {
      background-color: ${borderColor.secondaryLightColor};
    }
  }
  ${moreThanBreakpoint("tablet")} {
    display: flex;
    align-items: center;
    padding: 0 ${space.atom2x};
    font-size: ${fontSize.caption};
  }
  ${moreThanBreakpoint("desktop")} {
    padding: 0 ${space.atom3x};
  }
`;
const tabSelectedStyle = css`
  color: ${accentColor.primaryColor};
  svg {
    path,
    rect {
      fill: ${accentColor.primaryColor};
    }
  }
  &:after {
    background-color: ${accentColor.primaryColor};
  }
  &:hover {
    color: ${accentColor.primaryColor};
    &:after {
      background-color: ${accentColor.primaryColor};
    }
  }
`;
const iconStyle = css`
  display: inline-block;
  i {
    font-size: ${fontSize.headline};
    vertical-align: middle;
    ${moreThanBreakpoint("tablet")} {
      position: relative;
      top: -1px;
    }
  }
`;
const iconMultiStyle = css`
  i {
    padding-left: 0.25rem;
    ${moreThanBreakpoint("tablet")} {
      padding-left: 0.25rem;
    }
  }
`;
const svgIconStyle = css`
  display: inline-block;
  margin-bottom: -5px;
  svg {
    path,
    rect {
      transition: fill 0.3s ease;
    }
  }
  ${moreThanBreakpoint("tablet")} {
    margin-bottom: 0;
  }
`;
const labelStyle = css`
  vertical-align: middle;
  padding-top: 0.2rem;
  line-height: 1;
  display: block;
  font-weight: ${fontWeight.bold};
  ${moreThanBreakpoint("tablet")} {
    padding-top: 0;
    padding-left: ${space.atom};
    display: inline;
    line-height: 41px;
  }
`;
const verticalMiddleStyle = css`
  vertical-align: middle;
`;

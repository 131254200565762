import React, { useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { CruiseDepartureDatePickerBlock } from "main/javascripts/components/cruise/CruiseDepartureDatePickerBlock";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { CruiseOriginBlock } from "main/javascripts/components/cruise/CruiseOriginBlock";
import { StaysBlock } from "main/javascripts/components/cruise/form/StaysBlock";
import { VesselBrandBlock } from "main/javascripts/components/cruise/VesselBrandBlock";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";

export const SearchCruiseFilterBlock = (): React.ReactElement => {
  const { t } = useTranslation(["button"]);

  const [isShowFilter, setIsShowFilter] = useState(false);

  const onClick = () => {
    setIsShowFilter(!isShowFilter);
  };

  const toggleText = isShowFilter
    ? t("common.hideFilter")
    : t("common.showFilter");

  return (
    <div>
      {isShowFilter && (
        <div>
          <CruiseDepartureDatePickerBlock borderColorStyleKey="secondary" />
          <div css={staysBlockStyle}>
            <StaysBlock borderColorStyleKey="secondary" />
          </div>
          <div css={col2BlockStyle}>
            <CruiseOriginBlock
              labelColorStyleKey="default"
              borderColorStyleKey="secondary"
            />
            <VesselBrandBlock
              labelColorStyleKey="default"
              borderColorStyleKey="secondary"
            />
          </div>
        </div>
      )}
      <div css={buttonBlockStyle}>
        <div css={buttonStyle} onClick={onClick}>
          {toggleText}
        </div>
      </div>
    </div>
  );
};

const buttonBlockStyle = css`
  display: flex;
`;
const buttonStyle = css`
  color: ${accentColor.primaryColor};
  cursor: pointer;
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption1};
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  padding: ${space.atom};
`;
const staysBlockStyle = css`
  ${moreThanBreakpoint("desktop")} {
    width: 50%;
  }
`;
const col2BlockStyle = css`
  ${moreThanBreakpoint("tablet")} {
    display: flex;
    justify-content: space-between;
  }
`;
